import React from "react";
// import { Link }         from "gatsby";
import styled from "styled-components";

import Seo from "../../components/seo";
// import Image            from "../../components/image";

import { Colors } from "../../theme";
import { Header, SubHeader, Body } from "../../components/Typography";
import {
  SiteContent,
  HalfWidth,
  ThreeQuarterWidth,
  // QuarterWidth,
  TwoThirdsWidth,
  ThirdWidth,
} from "../../components/Layouts";

import { FullWidthImageSlider } from "../../components/Sliders";

// Images
import OrganicCircles from "../../images/svgs/thumbsupstudio_organicCircles.svg";
import AngleStart from "../../images/svgs/thumbsupstudio_angle-start.svg";
import AngleEnd from "../../images/svgs/thumbsupstudio_angle-finish.svg";
import BakUSABG from "../../images/work/thumbsupstudio-bakusa-hero.jpg";
import BakUSALogo_old from "../../images/work/bakusa/thumbsupstudio-bakusa-oldlogo.svg";
import BakUSALogo_new from "../../images/work/bakusa/thumbsupstudio-bakusa-logo.svg";
import AtlasLogo_old from "../../images/work/bakusa/thumbsupstudio-bakusa-atlasold.svg";
import AtlasLogo from "../../images/work/bakusa/thumbsupstudio-bakusa-atlas.svg";
import SealLogo_old from "../../images/work/bakusa/thumbsupstudio-bakusa-sealold.svg";
import SealLogo from "../../images/work/bakusa/thumbsupstudio-bakusa-seal.svg";

import WebHero from "../../images/work/bakusa/thumbsupstudio-bakusa-web-hero.png";
import WebLong1 from "../../images/work/bakusa/thumbsupstudio-bakusa-web-long-1.jpg";
import WebLongMFG from "../../images/work/bakusa/thumbsupstudio-bakusa-web-long-mfg.jpg";
import WebLongHome from "../../images/work/bakusa/thumbsupstudio-bakusa-web-long-home.jpg";

import PrintHero from "../../images/work/bakusa/thumbsupstudio-bakusa-print-hero.png";
import PrintAtlas from "../../images/work/bakusa/thumbsupstudio-bakusa-print-atlas.jpg";
import PrintSeal from "../../images/work/bakusa/thumbsupstudio-bakusa-print-seal.jpg";
import PrintBrochure from "../../images/work/bakusa/thumbsupstudio-bakusa-print-brochure.jpg";
import BakUSAMobile from "../../images/work/bakusa/thumbsupstudio-BakUSA-AtlasPro.jpg";

import "../../theme/css/animations.css";

const AboutImageWrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: black;
  overflow: hidden;
  min-height: 1200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${Colors.Black};
  background-image: url(${BakUSABG});
`;

const BackgroundAnimation = styled.div`
  position: absolute;
  top: -940px;
  width: 100%;
  display: flex;
  min-height: 1600px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(${OrganicCircles});
  animation: subPagescale 6s ease-in-out infinite;
`;

const HeroWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  min-height: 700px;

  @media only screen and (max-width: 780px) {
    min-height: 500px;
  }
`;

const OverviewWrapper = styled.div`
  width: 100%;
  display: inline-block;
  min-height: 420px;
  padding-bottom: 36px;

  @media only screen and (max-width: 780px) {
    min-height: 520px;
  }
`;

const LogosWrapper = styled.div`
  @media only screen and (max-width: 780px) {
    padding-bottom: 130px;
  }
`;

const LogoSectionWrapper = styled.div`
  min-height: 270px;
  display: inline-block;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: 780px) {
    min-height: 370px;
  }
`;

const Title = styled.p`
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  color: ${({ white }) => (white ? Colors.White : Colors.Black)};
  padding-bottom: 9px;
  display: flex;
  line-height: 35px;
`;

const BeforeAfter = styled.h5`
  margin-bottom: 32px;
  margin-top: 18px;
  opacity: 0.4;
  color: ${Colors.White};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 4px;

  @media only screen and (max-width: 780px) {
    text-align: center;
    font-size: 21px;
    margin-bottom: 18px;
    margin-top: 42px;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
`;

const WebWrapper = styled.div`
  height: 100%;
  background-color: ${Colors.White};
  min-height: 800px;
  padding-bottom: 750px;

  @media only screen and (max-width: 680px) {
    padding-bottom: 250px;
  }
`;

const LongImage = styled.img`
  border-radius: ${({ small }) => (small ? 4 : 8)}px;
  box-shadow: 0px 20px 62px rgba(55, 158, 231, 0.21);

  @media only screen and (max-width: 680px) {
    border-radius: 4px;
  }
`;

const PrintWrapper = styled.div`
  height: 100%;
  background-color: ${Colors.Black};
  min-height: 800px;
  padding-bottom: 280px;
`;

const WebOverviewWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;

class LaunchNYPage extends React.Component {

  render() {
    return (
      <AboutImageWrapper>
        <Seo title="Thumbs Up Studio Portfolio - Web Design & Development" />
        <BackgroundAnimation></BackgroundAnimation>
        <HeroWrapper>
          <SiteContent center>
            <SubHeader large color={Colors.White}>
              Project
            </SubHeader>
            <Header h1 color={Colors.White}>
              Bak USA
            </Header>
          </SiteContent>
        </HeroWrapper>

        <OverviewWrapper
        >
          <SiteContent>
            <ThreeQuarterWidth>
              <Title white>Company overview</Title>
              <Body color={Colors.White}>
                Bak wasn’t just a computer company&#8212;it was a social
                enterprise. Committed to building computers in the United
                States, empowering people in urban communities, and changing
                lives around the world, Bak USA was a boon for social
                entrepreneurship.
                <br />
                <br />
                The company was brought to Buffalo, New York, to breathe life
                into an industry desperate for fresh air. The brand sought to
                “reinvent what it means to make it in America,” and that it did…
                until it didn’t.
                <br />
                <br />
                Sadly, the scrappy upstart ran into rising economic and
                political pressure and was forced to close its doors in November
                2018. Despite the unhappy ending, Bak USA’s print and digital
                marketing materials pushed as many boundaries as the company
                itself had pushed, and we will forever be proud of the all that
                we accomplished together.
              </Body>
            </ThreeQuarterWidth>
          </SiteContent>
        </OverviewWrapper>

        <SiteContent
        >
          <Title white>Logos</Title>
        </SiteContent>

        <LogosWrapper>
          <LogoSectionWrapper
          >
            <SiteContent wrap>
              <HalfWidth firstChild inline>
                <BeforeAfter h2 color={"rgba(255,255,255, .3)"}>
                  Before
                </BeforeAfter>
                <img
                  alt="back usa logo old"
                  height={"70px"}
                  src={BakUSALogo_old}
                />
              </HalfWidth>
              <HalfWidth inline>
                <BeforeAfter h2 color={Colors.White}>
                  After
                </BeforeAfter>
                <img
                  alt="back usa logo new"
                  height={"70px"}
                  src={BakUSALogo_new}
                />
              </HalfWidth>
            </SiteContent>
          </LogoSectionWrapper>

          <LogoSectionWrapper
          >
            <SiteContent wrap>
              <HalfWidth firstChild inline>
                <BeforeAfter h2 color={Colors.White}>
                  Before
                </BeforeAfter>
                <img alt="atlas logo old" height={"70px"} src={AtlasLogo_old} />
              </HalfWidth>
              <HalfWidth inline>
                <BeforeAfter h2 color={Colors.White}>
                  After
                </BeforeAfter>
                <img alt="atlas logo new" height={"70px"} src={AtlasLogo} />
              </HalfWidth>
            </SiteContent>
          </LogoSectionWrapper>

          <LogoSectionWrapper
          >
            <SiteContent wrap>
              <HalfWidth firstChild inline>
                <BeforeAfter h2 color={Colors.White}>
                  Before
                </BeforeAfter>
                <img alt="atlas logo old" height={"70px"} src={SealLogo_old} />
              </HalfWidth>
              <HalfWidth inline>
                <BeforeAfter h2 color={Colors.White}>
                  After
                </BeforeAfter>
                <img alt="atlas logo new" height={"70px"} src={SealLogo} />
              </HalfWidth>
            </SiteContent>
          </LogoSectionWrapper>
        </LogosWrapper>

        <WebWrapper>
          <ImageWrapper>
            <img
              alt="page element"
              src={AngleStart}
              style={{ marginTop: "-10px" }}
              width={"105%"}
            />
            <SiteContent
            >
              <img
                alt="hero"
                src={WebHero}
                style={{ marginTop: "-110px" }}
                width={"90%"}
              />
            </SiteContent>
          </ImageWrapper>

          <WebOverviewWrapper>
            <SiteContent
            >
              <ThirdWidth>
                <Title>Web Design</Title>
                <Body>
                  Unfortunately, most of Bak USA’s web designs and digital
                  animations went down with the ship.
                  <br />
                  <br /> All that remains today are these few images and
                  screenshots that differentiate Bak USA from its competitors.
                </Body>
              </ThirdWidth>
              <TwoThirdsWidth>
                <img
                  alt="phone with web design"
                  src={BakUSAMobile}
                  style={{ margin: "auto" }}
                  width={"90%"}
                />
              </TwoThirdsWidth>
            </SiteContent>
          </WebOverviewWrapper>

          <SiteContent noMaxWidth>
            <FullWidthImageSlider />
          </SiteContent>

          <SiteContent wrap>
            <TwoThirdsWidth>
              <LongImage
                src={WebLongHome}
                alt="Bak USA Home Page Design"
              />
            </TwoThirdsWidth>
            <ThirdWidth>
              <LongImage
                small
                src={WebLong1}
                alt="Bak USA Computers Page Design"
              />
              <LongImage
                small
                src={WebLongMFG}
                alt="Bak USA Manufacturing Page Design"
                style={{ marginTop: "9px" }}
              />
            </ThirdWidth>
          </SiteContent>
        </WebWrapper>

        <PrintWrapper>
          <ImageWrapper>
            <img
              alt="angle"
              src={AngleEnd}
              style={{ marginTop: "-210px" }}
              width={"105%"}
            />
            <SiteContent
              center
            >
              <img
                alt="print design materials"
                src={PrintHero}
                style={{ marginTop: "-510px" }}
                width={"90%"}
              />
            </SiteContent>
          </ImageWrapper>
          <SiteContent
          >
            <TwoThirdsWidth style={{ marginBottom: "150px" }}>
              <Title white>Print Design</Title>
              <Body color={Colors.White}>
                To prepare our sales team with marketing materials to share in
                the field, we put together some uniquely branded collateral that
                set Bak USA apart from global giants such as HP, Dell, Apple,
                and Samsung.
              </Body>
            </TwoThirdsWidth>
          </SiteContent>
          <SiteContent
            wrap
          >
            <HalfWidth style={{ marginBottom: "9px" }}>
              <img alt="atlas computer print materials" src={PrintAtlas} />
            </HalfWidth>
            <HalfWidth style={{ marginBottom: "9px" }}>
              <img alt="seal computer print materials" src={PrintSeal} />
            </HalfWidth>
          </SiteContent>
          <SiteContent
            center
          >
            <img
              alt="print materials for bak usa"
              style={{ marginTop: "9px" }}
              src={PrintBrochure}
            />
          </SiteContent>
        </PrintWrapper>
      </AboutImageWrapper>
    );
  }
}

export default LaunchNYPage;
